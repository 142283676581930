define(['app', 'leaflet', 'siteObj'], function(app, L, siteObj) {
  const locatorDetail = function() {

    const component = {};
    const  _config = {
      selectors: {
        header: 'header',
        toggles: '[data-js-element=toggleAccordion]',
        contentHolder: '[data-js-element=locatorDetail_contentHolder]',
        content: '[data-js-element=locatorDetail_accordion_content]',
        mapId: 'locatorDetail',
        detailMap: '[data-js-element=locatorDetail_map]'
      },
      classes: {
        open: 'locatorDetail_accordion_open',
        mapError: 'locatorDetail_error',
        customIcon: 'locatorDetail_customMarker',
        locatorDetailDone: 'locatorDetail-done'
      },
      customIcon: {
        currentLocation: 'locatorMap_currentLocation',
        beforeSearch: 'locatorMap_customMarker',
        afterSearch: 'locatorMap_customMarker-afterSearch',
        iconSize_width: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapIconWidth') ? siteObj.storeLocator.locatorMapIconWidth : 40,
        iconSize_height: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapIconHeight') ? siteObj.storeLocator.locatorMapIconHeight : 40,
        iconAnchor_width: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapAnchorWidth') ? siteObj.storeLocator.locatorMapAnchorWidth : 20,
        iconAnchor_height: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapAnchorHeight') ? siteObj.storeLocator.locatorMapAnchorHeight : 40,
      },
    };

    const _init = function(element) {
      let callbackName = component.JSONP(component.initDetailMap);
      component.element = element;
      component.toggles = component.element.querySelectorAll(component.config.selectors.toggles);
      component.loadMapsAPI(`https://maps.googleapis.com/maps/api/js?key=AIzaSyDAe9q-8nn_s6GdxTOsV1mI_JYpQR7wKuw&callback=${callbackName}`);
      component.addClickEventListeners(component.toggles);
      document.title = component.element.getAttribute('data-title');
    };

    const _addClickEventListeners = function(toggles) {
      for (let i = 0; i < toggles.length; i++) {
        toggles[i].addEventListener('click', component.accordion);
      }
    };

    const _accordion = function(e) {
      e.preventDefault();
      const el = e.currentTarget.parentElement;
      const ariaAttr = app.element.getAttribute('aria-expanded', e.currentTarget);
      const ariaAttrContent = el.querySelector(component.config.selectors.content);
      app.element.toggleClass(component.config.classes.open, el);

      if (ariaAttr === 'true') {
        app.element.setAttribute('aria-expanded', 'false', e.currentTarget);
        app.element.setAttribute('aria-hidden', 'true', ariaAttrContent);
      } else {
        app.element.setAttribute('aria-expanded', 'true', e.currentTarget);
        app.element.setAttribute('aria-hidden', 'false', ariaAttrContent);
        component.anchorTop(el);
      }
    };

    const _anchorTop = (currentTarget) => {
      let anchor = currentTarget.offsetTop;
      let contentHolder = document.querySelector(component.config.selectors.contentHolder);
      app.element.animateTo(contentHolder, anchor, 200);
    };

    const _JSONP = (callback) => {
      let timestamp = Date.now();
      let generatedFunctionName = 'jsonp' + Math.round(timestamp + Math.random() * 1000001);

      window[generatedFunctionName] = () => {
        callback();
        window[generatedFunctionName] = undefined;
      };

      setTimeout(() => {
        if (window[generatedFunctionName]) {
          window[generatedFunctionName] = undefined;
          component.errorMessage();
        }
      }, 5000);

      return generatedFunctionName;
    };

    const _errorMessage = () => {
      let mapTile = document.querySelector(component.config.selectors.detailMap);
      app.element.addClass(component.config.classes.mapError, mapTile);
      mapTile.innerHTML = `<div class="locatorDetail_error_message">Sorry, Something went wrong
                                        </div>
                                        <div class="locatorDetail_error_refresh">Please try refreshing the page</div>`;
    };

    const _initDetailMap = function() {
      let latlong = app.element.getAttribute('data-latLong', component.element);
      let markerData = latlong.split(',');
      component.locatorDetail = L.map(component.config.selectors.mapId, {
        minZoom: 3
      }).setView(markerData, 15);

      component.getMapStyle('/mapStyling.locator');

      component.loadMarker(markerData);
    };

    const _getMapStyle = (query) => {
      app.ajax.get({
        url: query,
        success: component.onSuccess,
        error: component.onError
      });
    };

    const _onSuccess = (res) => {
      let mapTile = document.querySelector(component.config.selectors.detailMap);
      component.jsonObj = JSON.parse(res);
      component.mapStyle = component.jsonObj.mapStyle !== undefined ? component.jsonObj.mapStyle : null;
      app.element.addClass(component.config.classes.locatorDetailDone, mapTile);
      L.gridLayer.googleMutant({
        type: 'roadmap',
        styles: component.mapStyle
      }).addTo(component.locatorDetail);
    };

    const _onError = (res) => {
      app.console.error(res);
    };

    const _loadMarker = function(markerData) {
      let icon = L.divIcon({
        className: component.config.classes.customIcon,
        iconSize: [component.config.customIcon.iconSize_width , component.config.customIcon.iconSize_height],
        bgPos: [0, 0],
        iconAnchor: [component.config.customIcon.iconAnchor_width, component.config.customIcon.iconAnchor_height],
      });

      let marker = L.marker(markerData, {
        icon: icon
      });

      marker.addTo(component.locatorDetail);
    };

    const _loadMapsAPI = (url) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.src = url;
      document.body.appendChild(script);
    };

    component.config = _config;
    component.init = _init;
    component.initDetailMap = _initDetailMap;
    component.getMapStyle = _getMapStyle;
    component.onSuccess = _onSuccess;
    component.onError = _onError;
    component.errorMessage = _errorMessage;
    component.anchorTop = _anchorTop;
    component.JSONP = _JSONP;
    component.loadMapsAPI = _loadMapsAPI;
    component.loadMarker = _loadMarker;
    component.accordion = _accordion;
    component.addClickEventListeners = _addClickEventListeners;

    return component;
  };
  return locatorDetail;
});
